<template>
    <div class="ship-info-container">
        <div class="button-section">
            <div class="search-item">
                <span class="search-title">用户名：</span>
                <Input v-model="searchForm.userName" placeholder="请输入用户名" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">姓名：</span>
                <Input v-model="searchForm.realName" placeholder="请输入姓名" style="width: 150px"></Input>
            </div>
            <div class="search-item">
                <span class="search-title">手机号码：</span>
                <Input v-model="searchForm.phone" placeholder="请输入手机号码" style="width: 150px"></Input>
            </div>
            <Button @click="searchHandler" style="margin-left: 20px" type="success" class="btn">搜索</Button>
            <Button @click="resetHandler" style="margin-left: 20px" type="default" class="btn">重置</Button>
            <Button type="success" class="btn" @click="breakInputHandler">新建账户</Button>
            <!-- <Button type="error" class="btn">删除账户</Button> -->
        </div>
        <!-- 查询数据部分 -->
        <Table :loading="loading" :data="userList" :columns="columns"></Table>
        <Page @on-change="pageChange" @on-page-size-change="pageSizeChange" :total="total" size="small" show-total
            show-elevator show-sizer style="margin-top: 20px"></Page>
        <!-- 添加和修改窗口 -->
        <Modal :title="titleValue[dialogStatus]" v-model="detailModal" width="20%">
            <Form ref="formInline" :model="formInline" :rules="ruleInline" :label-widt="70" hide-required-mark
                label-position="right">
                <FormItem v-for="item in itemcolumns" :key="item.key" :label="item.title" :prop="item.key">
                    <Input type="text" v-model="formInline[item.key]" :placeholder="item.title" />
                </FormItem>
            </Form>
            <template #footer>
                <div class="btn-section">
                    <Button type="info" class="btn" @click="handleSubmit('formInline')">提交
                    </Button>
                    <Button style="margin-left: 20px" type="warning" class="btn" @click="handleClose('formInline')">取消
                    </Button>
                </div>
            </template>
        </Modal>
        <!-- 删除窗口 -->
        <Modal title="删除" v-model="deleteModal" width="20%">
            <div style="text-align: center; font-size: 15px">
                <p style="color: indianred">
                    <Icon style="font-size: large" type="ios-information-circle"></Icon>此操作将会清除该数据！
                </p>
                <p style="color: indianred">确认删除吗？</p>
            </div>
            <template #footer>
                <Button type="error" size="large" long @click="deleteSubmit()">确认</Button>
                <Button type="default" size="large" long style="margin: 2px 0" @click="deleteModal = false">
                    取消
                </Button>
            </template>
        </Modal>
    </div>
</template>

<script>
import {
    addUserInfo,
    deleteUserInfo,
    getUserInfoList,
    resetUserPassword,
    updateUserInfo,
} from "../../utils/api";
export default {
    data() {
        return {
            //删除样式
            deleteModal: false,
            // 新增和修改样式
            titleValue: {
                create: "新增",
                update: "修改",
                reset: "重置",
            }, //title信息
            dialogStatus: "", //标题信息
            detailModal: false, //是否关窗

            // 新增和修改数据部分
            userid: "",
            formInline: {}, //表单数据
            ruleInline: {
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                realName: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: "blur",
                    },
                ],
                type: [
                    {
                        required: true,
                        message: "请选择类型",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入手机号码",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        required: true,
                        message: "请输入邮箱地址",
                        trigger: "blur",
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur",
                    },
                ],
            }, //表单规则

            //查询数据部分
            searchForm: {}, //条件查询数据
            userList: [],
            loading: false,
            total: "",
            columns: [
                {
                    title: "用户名",
                    key: "userName",
                    width: 200,
                },
                {
                    title: "姓名",
                    key: "realName",
                    width: 100,
                },
                {
                    title: "账号类型",
                    key: "type",
                    minWidth: 150,
                },
                {
                    title: "手机号码",
                    key: "phone",
                    width: 150,
                },
                {
                    title: "邮箱地址",
                    key: "email",
                    width: 150,
                },
                {
                    title: "创建时间",
                    key: "createTime",
                    width: 200,
                },
                {
                    title: "操作",
                    key: "action",
                    width: 150,
                    align: "center",
                    render: (h, params) => {
                        return h("div", { class: "can-click" }, [
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.handleUpdate(params.row);
                                        },
                                    },
                                },
                                "修改"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.handleReset(params.row);
                                        },
                                    },
                                },
                                "重置密码"
                            ),
                            h(
                                "span",
                                {
                                    on: {
                                        click: () => {
                                            this.handleDelete(params.row);
                                        },
                                    },
                                },
                                "删除"
                            ),
                        ]);
                    },
                },
            ],
            pageSize: 10,
            pageNum: 1,
        };
    },
    created() {
        this.getUserInfoListAction();
    },
    computed: {
        itemcolumns: function () {
            //去除多余字段
            let list;
            if (this.dialogStatus == "create") {
                //新增部分
                list = this.columns.filter(
                    (item) =>
                        item.key != "action" &&
                        item.key != "createTime" &&
                        item.key != "type"
                );
                list.push({
                    //新增部分加入密码字段
                    title: "密码",
                    key: "password",
                });
            } else if (this.dialogStatus == "update") {
                //修改部分
                list = this.columns.filter(
                    (item) =>
                        item.key != "action" &&
                        item.key != "createTime" &&
                        item.key != "userName" &&
                        item.key != "type"
                );
            } else if (this.dialogStatus == "reset") {
                //重置部分
                list = [
                    {
                        title: "密码",
                        key: "password",
                    },
                ];
            }
            return list;
        },
    },
    methods: {
        // 删除窗体方法
        handleDelete(row) {
            this.deleteModal = true;
            this.userid = row.id;
        },
        //删除提交方法
        deleteSubmit() {
            deleteUserInfo([this.userid]).then((res) => {
                if (res.code == 0) {
                    this.$Message.success("操作成功!");
                    this.getUserInfoListAction(); //刷新
                    this.deleteModal = false; //关窗
                } else {
                    this.$Message.error(res.msg);
                }
            });
        },
        // 重置窗体方法
        handleReset(row) {
            this.detailModal = true; //开窗
            this.formInline = Object.assign({}, row);
            this.formInline.id = row.id;
            // console.log("aaa" + JSON.stringify(this.formInline));
            this.dialogStatus = "reset";
        },
        // 修改窗体方法
        handleUpdate(row) {
            this.formInline = Object.assign({}, row); //回显数据
            //console.log("aaa" + JSON.stringify(this.formInline));
            this.formInline.id = row.id; //加入id
            this.dialogStatus = "update"; //对应标题
            this.detailModal = true; //开窗
        },
        // 新增窗体方法
        breakInputHandler() {
            this.formInline = {}; //清空数据
            this.dialogStatus = "create"; //对应标题
            this.detailModal = true; //开窗
        },

        // 提交表单按钮方法
        handleSubmit(name) {
            this.$refs[name].validate((valid) => {
                //验证表单
                if (valid) {
                    switch (
                    this.dialogStatus //区分新增和修改
                    ) {
                        case "create": //新增
                            //console.log("add信息:" + JSON.stringify(this[name]));
                            addUserInfo(this[name])
                                .then((res) => {
                                    if (res.code == 0) {
                                        this.$Message.success("操作成功!");
                                        this.getUserInfoListAction(); //刷新
                                        this.detailModal = false; //关窗
                                    } else {
                                        this.$Message.error(res.msg);
                                    }
                                })
                                .catch((error) => {
                                    this.$Message.error(error.message + "!");
                                });
                            break;
                        case "update": //修改
                            // console.log("更新");
                            // console.log(JSON.stringify(this.formInline));
                            updateUserInfo(this.formInline)
                                .then((res) => {
                                    if (res.code == 0) {
                                        this.$Message.success("操作成功!");
                                        this.getUserInfoListAction();
                                        this.detailModal = false;
                                    } else {
                                        this.$Message.error(res.msg);
                                    }
                                })
                                .catch((error) => {
                                    this.$Message.error(error.message + "!");
                                });
                            break;
                        case "reset":
                            {
                                //重置
                                //console.log("重置源数据" + JSON.stringify(this.formInline));
                                let list = {
                                    id: this.formInline.id,
                                    newPassword: this.formInline.password,
                                };
                                //console.log("重置传递数据" + JSON.stringify(list));
                                resetUserPassword(list).then((res) => {
                                    if (res.code == 0) {
                                        this.$Message.success("操作成功!");
                                        this.getUserInfoListAction();
                                        this.detailModal = false;
                                    } else {
                                        this.$Message.error(res.msg);
                                    }
                                });
                            }
                            break;
                        default:
                            break;
                    }
                } else {
                    this.$Message.error("操作失败!");
                }
            });
        },
        //表单取消按钮方法
        handleClose(name) {
            this.$refs[name].resetFields(); //重置操作
            this.formInline = {}; //清空数据
            this.detailModal = false; //关窗
        },
        //查询部分
        resetHandler() {
            //重置
            this.pageSize = 10;
            this.pageNum = 1;
            this.searchForm = {};
            this.getUserInfoListAction();
        },
        searchHandler() {
            //条件查询
            this.getUserInfoListAction();
        },
        getUserInfoListAction() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                pageParamDTO: {},
            };
            if (this.searchForm.userName)
                params.pageParamDTO.userName = this.searchForm.userName;
            if (this.searchForm.realName)
                params.pageParamDTO.realName = this.searchForm.realName;
            if (this.searchForm.phone)
                params.pageParamDTO.phone = this.searchForm.phone;
            console.log("用户信息获取参数" + JSON.stringify(params));
            getUserInfoList(params).then((res) => {
                if (res.code == 0) {
                    this.total = res.data.total;
                    let list = res.data.list;
                    list.forEach((item) => {
                        switch (item.type) {
                            case 0:
                                item.type = "普通账号";
                                break;
                            case 1:
                                item.type = "管理员";
                                break;
                            default:
                                item.type = "未定义";
                                break;
                        }
                    });
                    this.userList = list;
                }
            });
        },
        pageChange(num) {
            this.pageNum = num;
            this.getUserInfoListAction();
        },
        pageSizeChange(size) {
            this.pageSize = size;
            this.getUserInfoListAction();
        },
    },
};
</script>

<style lang="scss">
.ship-info-container {
    height: 100%;
    padding: 30px;

    .button-section {
        display: flex;
        padding-bottom: 20px;

        .search-item {
            margin-left: 20px;

            &:first-child {
                margin-left: 0;
            }

            .search-title {
                font-size: 16px;
                color: #fff;
            }
        }

        .btn {
            margin-left: 10px;

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-left: auto;
                margin-right: 20px;
            }
        }
    }
}

.can-click span {
    cursor: pointer;
}
</style>
